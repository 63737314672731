import React from "react"

export const TitleUnderlineSipTrunkOne = ({ underline }) => (
  <span>
    {underline}
    <svg width="238" height="10" viewBox="0 0 238 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M44.2479 10C39.3274 9.07169 3.29797 10.1081 0.679135 9.82831C-1.4589 9.45317 1.95786 2.84689 3.94245 1.87407C4.63807 1.52436 5.44623 1.47351 7.29782 1.63247C10.2338 1.93131 48.1966 1.33997 48.1966 1.33997C48.6979 1.54344 50.2017 0.856744 50.4779 1.10472C51.3474 1.68332 112.88 0.894891 118.128 0.8631C121.309 0.844025 118.793 1.23824 122.537 1.07928C130.516 0.761364 147.907 1.06657 153.952 0.704142C158.791 0.405302 159.835 0.392594 164.479 0.63421C169.226 0.850392 194.463 0.538832 197.347 0.074676C198.053 -0.0524901 198.595 -0.00797814 198.595 0.138263C198.595 0.170054 216.385 0.278142 216.467 0.392591C216.6 0.538832 217.142 0.608769 217.643 0.507036C218.574 0.354437 237.51 -0.198737 237.806 0.227269C238.737 1.44806 236.129 6.64915 233.929 7.91446C231.679 9.26878 219.321 7.74915 209.388 8.56302L163.139 8.64568L153.226 9.15433C152.091 8.71561 147.722 9.82196 145.083 9.03353C143.968 8.72197 136.091 8.67745 134.352 9.00809C132.838 9.26242 92.5429 8.62023 88.41 9.50403C81.0446 8.76647 51.1735 9.82197 44.2479 10Z" fill="#FFBA00" />
    </svg>
  </span>
)

export const TitleUnderlineSipTrunkTwo = ({ underline }) => (
  <span>
    {underline}
    <svg width="219" height="10" viewBox="0 0 219 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M40.7155 10C36.1878 9.07169 3.03468 10.1081 0.624918 9.82831C-1.34243 9.45317 1.80156 2.84689 3.62771 1.87407C4.26781 1.52436 5.01144 1.47351 6.71522 1.63247C9.4168 1.93131 44.349 1.33997 44.349 1.33997C44.8102 1.54344 46.194 0.856744 46.4481 1.10472C47.2482 1.68332 103.868 0.894891 108.697 0.8631C111.625 0.844025 109.309 1.23824 112.754 1.07928C120.097 0.761364 136.099 1.06657 141.662 0.704142C146.115 0.405302 147.075 0.392594 151.348 0.63421C155.716 0.850392 178.938 0.538832 181.593 0.074676C182.242 -0.0524901 182.741 -0.00797814 182.741 0.138263C182.741 0.170054 199.111 0.278142 199.186 0.392591C199.308 0.538832 199.807 0.608769 200.268 0.507036C201.125 0.354437 218.549 -0.198737 218.822 0.227269C219.678 1.44806 217.278 6.64915 215.254 7.91446C213.183 9.26878 201.812 7.74915 192.672 8.56302L150.115 8.64568L140.994 9.15433C139.949 8.71561 135.93 9.82196 133.501 9.03353C132.475 8.72197 125.227 8.67745 123.627 9.00809C122.233 9.26242 85.155 8.62023 81.3521 9.50403C74.5746 8.76647 47.0882 9.82197 40.7155 10Z" fill="#FFBA00" />
    </svg>
  </span>
)